/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "Hayden Farms",
  gtagId: "G-822ELP7J7Q",
  logo: {
    src: "https://cdn.maxxpm.com/haydenfarms/logosandbanners/logo_ds@3x.png",
    alt: "Hayden Farms Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "12570 N. Cotton Lane",
    cityStateZip: "Surprise, AZ 85388",
    phone: "(623) 292-7375",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/haydenfarms/logosandbanners/logo_white@3x.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/haydenfarms/logosandbanners/hf_list.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0055-Edit.jpg",
      video: undefined,
      title: "We Can't Wait to Welcome You Home",
      subtitle: "Apply Today!",
    },
    aboutUs: {
      paragraphs: [
        "Discover a new lifestyle located within minutes from Highway 60 and the El Mirage Community Center. Visit the Westgate Entertainment District nearby for an array of fun shops and unique restaurants or the Gila River Arena to see your favorite band in concert. The NFL’s Arizona Cardinals also play close by at State Farm Stadium, where you can visit for a Football Game, Concert, or the Annual Fiesta Bowl!",
        // "Residents of Hayden Farms enjoy access to exclusive amenities, including a temperature-controlled pool, and a gorgeous clubhouse. With an exciting tot lot for children and a dog park for your pets, Hayden Farms is a family-friendly community. Our 3 different models of townhomes include open floorplans, tons of natural light, attached garages and more. We can't wait to welcome you home!",
        "Hayden Farms 3 different models of townhomes include open floorplans, tons of natural light, attached garages and more. We can't wait to welcome you home!",
      ],
      image: {
        src: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0071-Edit.jpg",
        alt: "Staged Living Room",
      },
    },
    floorplanTitle: "Explore Our Floor Plans and Take a 3D Virtual Tour",
    floorplans: [
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/haydenfarms/hf_payton.png",
          alt: "Payton Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=Kv6xCPwrJH2",
          title: "Payton model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/haydenfarms/hf_delinda.png",
          alt: "Delinda Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=xMA7R5q32QG",
          title: "Delinda model 3d walkthrough",
        },
      },
      {
        hasImage: true,
        image: {
          src: "https://cdn.maxxpm.com/haydenfarms/hf_tucker.png",
          alt: "Tucker Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=yAogg43HErH",
          title: "Tucker model 3d walkthrough",
        },
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/haydenfarms/Hayden Banner 2.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0018-Edit.jpg",
        alt: "Hayden Farms exterior photo",
      },
      {
        src: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0006-Edit.jpg",
        alt: "Hayden Farms playground",
      },
      {
        src: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0020-Edit.jpg",
        alt: "Hayden Farms exterior photo",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/haydenfarms/hayden pets.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/haydenfarms/Exterior Images/DSC_0055-Edit.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-HF",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/lagunafarms/logoandbanners/girls+on+bed+with+dog.jpg",
    },
    dnaRequired: true,
  },
};
